

export default async function(url: string, redirectCode: number) {
    if (process.client) {
        navigateTo(url, {redirectCode: redirectCode, external: true});
    }
    if (process.server) {
        const event = useRequestEvent();
        await sendRedirect(event, url, redirectCode);
    }
}
